import { saveAs } from "file-saver";
import { uniqueId as _uniqueId, trim } from "lodash";
import mermaid from "mermaid";
import * as QueryString from "query-string";
import * as React from "react";
import { Helmet } from "react-helmet";
import { TagCloud } from "react-tagcloud";
import { log } from "util";
import { parseString } from "xml2js";
import "./App.css";

mermaid.mermaidAPI.initialize({
  securityLevel: "antiscript",
  startOnLoad: false,
});

const fileNameOfEntry = "{0}-Entry.json";
const fileNameOfFilteredStream = "{0}-{1}.json";
const fileNameOfFullStream = "{0}-Stream.json";

interface IAppProps {
  dummy: string;
}

interface IAppState {
  description: string;
  entries: IEntry[];
  link: string;
  loading: boolean;
  loadSuccess: boolean;
  selectedTag: string | null;
  styling: string;
  title: string;
}

interface IEntry {
  createdOn: string;
  id: string;
  ordering: string;
  pos: number;
  rawText: string;
  tags: string[];
  text: string;
  uniqueId: string;
}

interface ITagCloudTag {
  count: number;
  value: string;
  visible: boolean;
}

class App extends React.Component<IAppProps, IAppState> {
  private isLegacy: boolean;

  public constructor(props: IAppProps) {
    super(props);

    // assign state directly here,
    // don't use this.setState() here
    this.state = {
      description: "",
      entries: [],
      link: "",
      loadSuccess: false,
      loading: true,
      selectedTag: null,
      styling: "",
      title: "",
    };

    this.createEntryForJson = this.createEntryForJson.bind(this);
    this.decorateArrayForJson = this.decorateArrayForJson.bind(this);

    this.renderTagCloudTag = this.renderTagCloudTag.bind(this);
    this.downloadEntry = this.downloadEntry.bind(this);
    this.downloadFilteredStream = this.downloadFilteredStream.bind(this);
    this.downloadFullStream = this.downloadFullStream.bind(this);
    this.selectTag = this.selectTag.bind(this);
    this.selectTag2 = this.selectTag2.bind(this);
    this.setEntryRef = this.setEntryRef.bind(this);
    this.showDiagrams = this.showDiagrams.bind(this);
    this.unselectTag = this.unselectTag.bind(this);

    this.isLegacy = false;
  }

  public componentDidMount() {
    const parsed = QueryString.parse(document.location.search);

    let urlAddon = "";
    if (document.location.hostname.indexOf("-staging") > -1 || document.location.hostname === "localhost") {
      urlAddon = "-staging";
    }

    this.isLegacy = parsed.mode !== "next";
    const id = parsed.rss;
    // CL Legacy: https://campaign-logger.com/gateway/api/logs/published/f64241a76b1f4fa6be4c7bebe878e035.rss
    // CL vNext: https://logger.campaign-logger.com/api/rss/8edaaf220a644e6dacf47831cb9fd4b1
    const rssUrl = this.isLegacy
      ? `https://campaign-logger.com/gateway/api/logs/published/${id}.rss`
      : `https://logger${urlAddon}.campaign-logger.com/api/rss/${id}`;

    const filesUrl = `https://logger${urlAddon}.campaign-logger.com/api/files/`;

    function getValue(node: any): string {
      let value = node || "";

      if (Array.isArray(value) && value.length > 0) {
        value = value[0];
      }

      if (typeof value === "object" && value._) {
        value = value._;
      }

      if (typeof value === "object" && value.$) {
        value = "";
      }

      return value;
    }

    setTimeout(() => {
      fetch(rssUrl, {}).then(
        (response) => {
          response.text().then((text) => {
            parseString(text, (err, result) => {
              result = result || { rss: { channel: [{}] } };
              result = result.rss.channel[0];

              const title = getValue(result.title);
              const link = getValue(result.link);
              const description = getValue(result.description);
              const styling = getValue(result.styling);

              const entries: IEntry[] = [];
              try {
                for (const item of result.item) {
                  const entryText: string = getValue(item.description);
                  const category: string[] = item.category || [];
                  const rawText: string = getValue(item.entryRawText);
                  const uniqueId: string = getValue(item.uniqueId);
                  const createdOn: string = trim(getValue(item.createdOn) || "", '"');
                  const ordering: string = getValue(item.ordering) || `auto:${createdOn}`;

                  entries.push({
                    createdOn,
                    id: "entry-" + entries.length,
                    ordering,
                    pos: result.item.length - entries.length,
                    rawText,
                    tags: category,
                    text: (entryText || "").replace(/(src|href)="logger-files:([a-z0-9]{32})"/g, `$1="${filesUrl}$2"`),
                    uniqueId,
                  });
                }
              } catch (ex) {
                log(ex);
              }

              entries.sort((a, b) => (a.ordering > b.ordering ? 1 : -1));

              this.setState({
                description,
                entries,
                link,
                loadSuccess: entries.length > 0,
                loading: false,
                styling,
                title,
              });
            });
          });
        },
        (error) => {
          log(error);
        },
      );
    }, 100);
  }

  public render() {
    const selectedTagInfo =
      this.state.selectedTag != null ? (
        <div className="alert alert-info" role="alert">
          <p className="text-center">
            Showing entries with tag {this.state.selectedTag} -{" "}
            <a href="#" onClick={this.unselectTag}>
              Remove filtering
            </a>{" "}
            -{" "}
            <a href="#" onClick={this.downloadFilteredStream}>
              Download filtered JSON
            </a>
          </p>
        </div>
      ) : (
        ""
      );
    const entries = this.state.entries.map((e, index) =>
      this.state.selectedTag == null ||
      e.tags.map((t) => t.toLowerCase()).indexOf(this.state.selectedTag.toLowerCase()) >= 0 ? (
        <li
          className="panel panel-default"
          key={e.id}
          data-meta-created-on={e.createdOn}
          data-meta-ordering={e.ordering}
        >
          <div className="entry-meta">
            <div className="entry-meta-pos">{index + 1}</div>
            <div
              className="entry-meta-download"
              title="Download"
              onClick={this.downloadEntry}
              data-raw-text={e.rawText}
              data-unique-id={e.uniqueId}
            >
              &nbsp;
            </div>
          </div>
          <div className="entry-meta-right">
            <div className="entry-meta-tags">
              {e.tags.map((t) => (
                <div key={t}>
                  <a href="#" onClick={this.selectTag} data-tag-symbol={t.substr(0, 1)} data-tag-value={t.substr(1)}>
                    {t}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="panel-body" dangerouslySetInnerHTML={{ __html: e.text }} ref={this.setEntryRef} />
        </li>
      ) : (
        ""
      ),
    );

    const accumulatedCounts = this.state.entries.reduce(
      (accu, curr) => {
        for (const t of curr.tags) {
          if (!accu[t]) {
            accu[t] = 0;
          }
          accu[t]++;
        }
        return accu;
      },
      {} as { [key: string]: number },
    );

    const sortable: ITagCloudTag[] = [];
    let tagCloudData: ITagCloudTag[] = [];
    for (const key in accumulatedCounts) {
      if (accumulatedCounts.hasOwnProperty(key)) {
        const data = {
          count: accumulatedCounts[key],
          value: key,
          visible: false,
        };

        sortable.push(data);
        tagCloudData.push(data);
      }
    }

    sortable.sort((a, b) => b.count - a.count);
    for (let i = 0; i < sortable.length && i < 40; i++) {
      sortable[i].visible = true;
    }

    tagCloudData = tagCloudData.filter((value) => value.visible);

    return (
      <>
        {/* @ts-ignore */}
        <Helmet>
          <style>{this.state.styling}</style>
        </Helmet>
        <div className="cl-nav-bar">
          <div className="container">
            <a href="http://www.roleplayingtips.com/campaign-logger/">About Campaign Logger</a>
            <a href="https://campaign-logger.com/#/disclaimer">Disclaimer</a>
            <a href="https://campaign-logger.com/#/lnotice">Impressum</a>
          </div>
        </div>
        {this.state.loading ? (
          <div className="intro">
            <h1>Loading...</h1>
          </div>
        ) : this.state.loadSuccess ? (
          <div className="container">
            <h1 className="stream-name">{this.state.title}</h1>
            <p className="lead">
              Load this stream as an{" "}
              <a href={this.state.link} target="_blank">
                RSS
              </a>{" "}
              feed in your favorite reader app.
            </p>
            <p>
              ...or download a{" "}
              <a href="#" onClick={this.downloadFullStream} target="_blank">
                JSON
              </a>{" "}
              file for import into Campaign Logger.
            </p>
            <p>
              If the following content infringes any rights or represents any other illegal activity, please inform us,
              so that we can review and remove this content. You can reach us at{" "}
              <a href="mailto:support@campaign-logger.com">support@campaign-logger.com</a>.
            </p>
            <hr />
            <div className="panel panel-default">
              <div className="panel-body text-center">
                {/* @ts-ignore */}
                <TagCloud
                  className="tag-cloud"
                  minSize={12}
                  maxSize={24}
                  tags={tagCloudData}
                  disableRandomColor={true}
                  shuffle={false}
                  renderer={this.renderTagCloudTag}
                />
              </div>
            </div>
            {selectedTagInfo}
            <ol className="entries">{entries}</ol>
          </div>
        ) : (
          <div className="intro">
            <h1>No data!</h1>
          </div>
        )}
      </>
    );
  }

  private createEntryForJson(entry: IEntry) {
    return { rawText: entry.rawText, uniqueId: entry.uniqueId };
  }

  private decorateArrayForJson(entries: IEntry[]) {
    if (this.isLegacy) {
      return entries.map((entry) => this.createEntryForJson(entry));
    }

    return {
      logEntries: entries.map((entry) => this.createEntryForJson(entry)),
      title: this.state.title,
      type: "log",
      version: 2,
    };
  }

  private downloadEntry(e: React.MouseEvent<HTMLDivElement>): void {
    const rawText = e.currentTarget.dataset.rawText;
    const uniqueId = e.currentTarget.dataset.uniqueId;
    const download = JSON.stringify(this.decorateArrayForJson([{ rawText, uniqueId } as IEntry]));

    e.preventDefault();

    const blob = new Blob([download], { type: "application/json" });
    saveAs(blob, fileNameOfEntry.replace("{0}", this.state.title));
  }

  private downloadFilteredStream(e: React.MouseEvent<HTMLAnchorElement>): void {
    const selectedTag = this.state.selectedTag || "";
    const download = JSON.stringify(
      this.decorateArrayForJson(this.state.entries.filter((entry) => entry.tags.indexOf(selectedTag) >= 0)),
    );

    e.preventDefault();

    const blob = new Blob([download], { type: "application/json" });
    saveAs(blob, fileNameOfFilteredStream.replace("{0}", this.state.title).replace("{1}", selectedTag.substr(1)));
  }

  private downloadFullStream(e: React.MouseEvent<HTMLAnchorElement>): void {
    const download = JSON.stringify(this.decorateArrayForJson(this.state.entries));

    e.preventDefault();

    const blob = new Blob([download], { type: "application/json" });
    saveAs(blob, fileNameOfFullStream.replace("{0}", this.state.title));
  }

  private renderTagCloudTag(tag: ITagCloudTag, size: number, color: string) {
    return (
      <a
        key={tag.value}
        href="#"
        onClick={this.selectTag}
        data-tag-symbol={tag.value.substr(0, 1)}
        data-tag-value={tag.value.substr(1)}
        style={{ fontSize: size + "px", margin: "5px" }}
      >
        {tag.value}
      </a>
    );
  }

  private selectTag(e: React.MouseEvent<HTMLAnchorElement>): void {
    const tagSymbol = e.currentTarget.dataset.tagSymbol ?? "";
    const tagValue = e.currentTarget.dataset.tagValue ?? "";

    e.preventDefault();

    this.selectTag2(tagSymbol + tagValue);
  }

  private selectTag2(tag: string): void {
    this.setState({ selectedTag: tag });
  }

  private setEntryRef(element: HTMLDivElement) {
    if (!element) {
      return;
    }

    const anchors = element.getElementsByTagName("a");
    for (const i in anchors) {
      if (!anchors.hasOwnProperty(i)) {
        continue;
      }

      const a = anchors[i];
      if (a.hasAttribute("data-tag-symbol")) {
        a.onclick = (e: MouseEvent) => {
          const currentTarget: HTMLAnchorElement = e.currentTarget as HTMLAnchorElement;
          if (currentTarget && currentTarget.attributes) {
            const tagSymbol = currentTarget.dataset.tagSymbol ?? "";
            const tagValue = currentTarget.dataset.tagValue ?? "";

            e.preventDefault();

            this.selectTag2(tagSymbol + tagValue);
          }
        };
      }
    }

    this.showDiagrams(element);
  }

  private showDiagrams(
    elementRef: HTMLDivElement,
    logger = {
      error: (err: { message: string }) => {
        log(err.message);
      },
    },
  ) {
    if (!elementRef) {
      return;
    }

    const containers = elementRef.getElementsByClassName("mermaid");
    if (!containers || containers.length === 0) {
      return;
    }

    for (let i = 0; i < containers.length; i += 1) {
      const container = containers[i];
      const textNode = container.firstChild;
      if (textNode && textNode.nodeType === 3) {
        const code = textNode.textContent || "";
        try {
          const id = _uniqueId("mermaid");
          mermaid.mermaidAPI.render(id, code, container).then((result) => {
            container.innerHTML = result.svg;
          });
        } catch (err) {
          if (logger && logger.error) {
            logger.error(err);
          }
        }
      }
    }
  }

  private unselectTag(e: React.MouseEvent<HTMLAnchorElement>): void {
    e.preventDefault();

    this.setState({ selectedTag: null });
  }
}

export default App;
